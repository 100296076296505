import { ErrorsData } from '@effy-tech/acquisition-core';

export const errorsList = <ErrorsData>[
  {
    statusCode: '503',
    message: 'erreur interne du serveur',
    isServeurError: true,
    metaData: {
      title: 'Erreur 500 Site indisponible | LPE',
      description:
        'Le site est actuellement indisponible. Nous serons de retour très bientôt !',
    },
  },
  {
    statusCode: '404',
    message: 'page non trouvée',
    isServeurError: false,
    links: [
      {
        url: '/fonctionnement',
        label: 'La Prime Énergie en bref',
      },
      {
        url: '/les-demarches',
        label: 'Les démarches pour toucher la Prime Énergie',
      },
      {
        url: '/les-travaux',
        label: "Les travaux d'économie d'énergie",
      },
      {
        url: '/parcours/1/prime',
        label: 'Calculateur de Prime Énergie',
      },
    ],
    metaData: {
      title: 'Erreur 404 Page non trouvée | LPE',
      description:
        "La page que vous recherchez n'existe pas. Découvrez nos autres offres et le guide de la rénovation énergétique sur https://www.https://www.pacte-energie-solidarite.fr/",
    },
  },
];
