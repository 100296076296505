import { Injectable } from '@angular/core';
import {
  ConfigService,
  FooterService,
  INewFooter,
  Site,
  WebsiteThemes,
  ctaSubject,
  getSiteUrl,
  getSocialLinks,
  socialsLinkText,
} from '@effy-tech/acquisition-core';
import { EffyIcons } from '@effy-tech/angular-common';

@Injectable({
  providedIn: 'root',
})
export class LpeFooterService extends FooterService {
  constructor(private readonly configService: ConfigService) {
    super();
  }

  getContent(): INewFooter {
    const env = this.configService.getEnv();
    return {
      logo: {
        url: 'assets/new-footer/logo_lpe_white.svg',
        alt: 'logo site La Prime Énergie',
      },
      title:
        'Une marque du groupe Effy,<span class="d-block">n°1 de la rénovation énergétique en ligne<span>',
      ctaSubject: ctaSubject,
      cta: {
        url: '/parcours/1/travaux-aides',
        title: 'Demander un devis',
      },
      siteLogo: {
        title: 'Les sites du groupe Effy',
        logos: [
          {
            url: 'https://www.effy.fr/common-assets/images/logo-effy-blanc.svg',
            alt: 'Logo Effy',
            link: getSiteUrl(Site.EFFY, env),
            site: WebsiteThemes.effy,
            icon: EffyIcons.EFFY_WHITE_LOGO
          },
          {
            url: 'assets/new-footer/logo_quelleenergie_white.svg',
            alt: 'Logo Quelle Énergie',
            link: getSiteUrl(Site.QE, env),
            site: WebsiteThemes.qe,
            icon: EffyIcons.QUELLEENERGIE_WHITE_LOGO
          },
          {
            url: 'assets/new-footer/logo_calculeo_white.svg',
            alt: 'Logo Calculeo',
            link: getSiteUrl(Site.CALCULEO, env),
            site: WebsiteThemes.calculeo,
            icon: EffyIcons.CALCULEO_WHITE_LOGO
          },
        ],
      },
      columns: [
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Guides de travaux',
            },
            {
              mainLink: false,
              link: 'Prime énergie chauffage',
              url: '/guide/chauffage-eligible-prime-energie',
            },
            {
              mainLink: false,
              link: 'Prime énergie isolation',
              url: '/guide/isolation-eligible-prime-energie',
            },
            {
              mainLink: false,
              link: 'Chaudière à granulés',
              url: '/guide/chaudiere-granules',
            },
            {
              mainLink: false,
              link: `Travaux d'isolation`,
              url: '/guide/financer-travaux-isolation',
            },
            {
              mainLink: false,
              link: 'Maisons écologiques',
              url: '/guide/maisons-ecologiques',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Conseils & Offres',
            },
            {
              mainLink: false,
              link: 'Isolation des combles',
              url: '/conseils-travaux/isolation/combles',
            },
            {
              mainLink: false,
              link: 'Isolation des murs',
              url: '/conseils-travaux/isolation/murs',
            },
            {
              mainLink: false,
              link: 'Isolation du sol',
              url: '/conseils-travaux/isolation/sols',
            },
            {
              mainLink: false,
              link: 'Pose pompe à chaleur',
              url: '/conseils-travaux/chauffage/pompe-a-chaleur',
            },
            {
              mainLink: false,
              link: 'Pose chauffage au gaz',
              url: '/conseils-travaux/chauffage/gaz',
            },
            {
              mainLink: false,
              link: 'FAQ',
              url: '/faq',
            },
          ],
        },
      ],
      socialsLink: {
        text: socialsLinkText,
        links: getSocialLinks(),
      },
      cguLinks: [
        {
          text: 'Mentions légales',
          url: '/mentions-legales',
        },
        {
          text: 'CGV',
          url: '/conditions-generales-vente',
        },
        {
          text: `CGU`,
          url: '/cgu',
        },
        {
          text: 'Vie Privée',
          url: '/vie-privee',
        },
        {
          text: 'Politique des cookies',
          url: '/politique-des-cookies',
        },
      ],
    };
  }
}
