import { IHeader } from '@effy-tech/acquisition-header';

export const HEADER_DATA = {
  navbar: {
    links: [
      {
        url: '',
        label: 'La prime énergie',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: '',
                    url: '',
                    links: [
                      {
                        url: '/fonctionnement/la-prime-energie-cest-quoi',
                        alt: 'prime-energie',
                        label: 'Présentation',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/accompagnement-prime.svg',
                      },
                      {
                        url: '/fonctionnement',
                        alt: 'accompagnement-prime',
                        label: 'Le concept',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/accompagnement-prime-2.svg',
                      },
                      {
                        url: '/les-demarches',
                        alt: 'etapes-prime',
                        label: "Comment l'obtenir ?",
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/etapes-prime.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: '/parcours/1/travaux-aides',
                  label: 'Recevoir ma prime',
                },
              },
              {
                type: 'image-wave',
                link: {
                  url: '/faq',
                  label: 'Toutes vos questions',
                },
                url: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/EFFY_HEARDER_264x254_Accompagnement%402x.jpg',
                width: 528,
                height: 508,
                alt: 'accompagnement',
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Nos offres',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: 'Isolation - les offres',
                    url: '/conseils-travaux/isolation',
                    links: [
                      {
                        url: '/conseils-travaux/isolation/combles',
                        alt: 'isolation-combles',
                        label: 'Combles',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/isolation-combles.svg',
                      },
                      {
                        url: '/conseils-travaux/isolation/murs',
                        alt: 'isolation-murs',
                        label: 'Murs',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/isolation-murs.svg',
                      },
                      {
                        url: '/conseils-travaux/isolation/sols',
                        alt: 'isolation-sol',
                        label: 'Sol',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/isolation-sol.svg',
                      },
                      {
                        url: '/conseils-travaux/isolation/fenetres',
                        alt: 'fenetres',
                        label: 'Fenêtres',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/fenetres.svg',
                      },
                      {
                        url: '/conseils-travaux/isolation/toiture',
                        alt: 'isolation-toiture',
                        label: 'Toiture',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/isolation-toiture.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: 'https://www.laprimeenergie.fr/parcours/1/isolation',
                  label: 'Isoler ma maison',
                },
              },
              {
                subItems: [
                  {
                    title: 'Chauffage - les offres',
                    url: '/conseils-travaux/chauffage',
                    links: [
                      {
                        url: '/conseils-travaux/chauffage/pompe-a-chaleur',
                        alt: 'pac',
                        label: 'Pompe à chaleur',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/pac.svg',
                      },
                      {
                        url: '/conseils-travaux/chauffage/gaz',
                        alt: 'gaz',
                        label: 'Chauffage au gaz',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/gaz.svg',
                      },
                      {
                        url: '/conseils-travaux/chauffage/bois',
                        alt: 'chauffage-bois',
                        label: 'Chauffage au bois',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/bois.svg',
                      },
                      {
                        url: '/conseils-travaux/chauffage/electrique',
                        alt: 'chauffage-electrique',
                        label: 'Chauffage électrique',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/electricite.svg',
                      },
                      {
                        url: '/conseils-travaux/chauffage/solaire',
                        alt: 'chauffage-solaire',
                        label: 'Chauffage solaire',
                        icon: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/solaire.svg',
                      },
                    ],
                  },
                ],
                type: 'list',
                ctaButton: {
                  url: 'https://www.laprimeenergie.fr/parcours/1/pompe-a-chaleur/',
                  label: 'Changer mon chauffage',
                },
              },
            ],
          },
        },
      },
      {
        url: '',
        label: 'Notre accompagnement',
        menu: {
          list: {
            items: [
              {
                subItems: [
                  {
                    title: 'Votre projet pas à pas',
                    url: '',
                    links: [
                      {
                        url: '/les-aides',
                        label: 'Comprendre les aides',
                      },
                      {
                        url: '/les-travaux',
                        label: 'Comprendre les travaux',
                      },
                      {
                        url: '/les-travaux/audit-energetique',
                        label: "L'audit énergétique",
                      },
                      {
                        url: '/les-travaux/les-travaux-deconomies-denergie/entreprise-rge',
                        label: 'Trouver votre artisan',
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                subItems: [
                  {
                    title: 'Vos aides financières',
                    url: '',
                    links: [
                      {
                        url: '/les-aides/ma-prime-renov',
                        label: "MaPrimeRénov'",
                      },
                      {
                        url: '/les-aides/la-tva-reduite',
                        label: 'La TVA réduite',
                      },
                      {
                        url: '/les-aides/leco-ptz',
                        label: "L'éco-prêt à taux zéro",
                      },
                    ],
                  },
                ],
                type: 'list',
              },
              {
                type: 'image-wave',
                link: {
                  url: '/qui-sommes-nous',
                  label: 'Qui sommes-nous ?',
                },
                url: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/fun_effy_sami%20copie%202.jpg',
                width: 528,
                height: 508,
                alt: 'qui-sommes-nous',
              },
            ],
          },
        },
      },
    ],
  },
  logo: {
    url: 'http://bo.laprimeenergie.fr/sites/default/files/2020-10/logo_lpe.svg',
    title: '',
    alt: 'Logo La Prime Energie',
    link: '/',
  },
  connection: {
    link: {
      url: 'https://www.effy.fr/espace-client/la-prime-energie/se-connecter',
      title: 'Espace client',
      options: {
        attributes: {
          target: '_self',
          rel: '',
        },
      },
      icon: {
        url: 'http://bo.laprimeenergie.fr/sites/default/files/2021-12/connexion-particulier-LPE.svg',
        width: 24,
        height: 24,
        alt: 'connexion-particulier',
      },
    },
    isConnected: false,
  },
  phoneInsert: {
    phoneHook: 'Appelez-nous !',
    phoneType: 'reglementaire',
    phoneNumber: '01 73 00 67 80',
    accompanimentPhone: {
      format: 'full_html',
      processed: '<p>du lundi au vendredi - 8h à 19h</p>\n',
      value: '<p>du lundi au vendredi - 8h à 19h</p>\r\n',
    },
  },
  linkPart: {
    url: 'https://www.laprimeenergie.fr/parcours/1/travaux-aides',
    title: 'DEMANDER UN DEVIS',
    options: {
      attributes: {
        target: '_self',
        rel: '',
      },
    },
  },
  env: null,
  isPro: false,
} as unknown as IHeader;
