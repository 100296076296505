import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfigService, IconsService, UtmCampaignComponent } from '@effy-tech/acquisition-core';
import { EffyIconsService, ThemeHelperService } from '@effy-tech/angular-common';

@Component({
  selector: 'lpe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, UtmCampaignComponent],
})
export class AppComponent implements OnInit {
  private readonly configService = inject(ConfigService);
  private readonly themeHelperService = inject(ThemeHelperService);
  private readonly iconsService = inject(IconsService);
  private readonly effyIconsService = inject(EffyIconsService);

  ngOnInit() {
    this.configService.initConfig();
    this.iconsService.init();
    this.themeHelperService.init();
    this.effyIconsService.init();
  }
}
