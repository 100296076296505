import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_ID, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { InMemoryScrollingOptions, provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import {
  AcquisitionThemeHelperService,
  BrowserStateInterceptor,
  ConfigModule,
  FooterService,
  WebsiteThemes,
} from '@effy-tech/acquisition-core';
import { DEFAULT_THEME, EFFY_ENVIRONMENT, provideEffyMaterial, ThemeHelperService } from '@effy-tech/angular-common';
import { ConsoleLoggerService, LoggerService } from '@effy-tech/common/loggers';
import { Theme } from '@effy-tech/design-system';
import { environment } from '@lpe-environments/environment';
import { LpeFooterService } from '@lpe-services/footer.service';
import { routes } from './app.routes';
import { errorsList } from './config/errors.config';
import { HEADER_DATA } from './config/header.config';
import { QueriesId_VALUES } from './config/variables';

const scrollConfig: InMemoryScrollingOptions = {
  anchorScrolling: 'enabled',
};

registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes, withEnabledBlockingInitialNavigation(), withInMemoryScrolling(scrollConfig)),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    [
      { provide: LoggerService, useClass: ConsoleLoggerService },
      { provide: APP_ID, useValue: 'laprimeenergie-front' },
      { provide: LOCALE_ID, useValue: 'fr' },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: BrowserStateInterceptor,
        multi: true,
      },
      {
        provide: FooterService,
        useClass: LpeFooterService,
      },
      { provide: EFFY_ENVIRONMENT, useValue: environment.env },
      { provide: DEFAULT_THEME, useValue: Theme.OLD_LPE },
      importProvidersFrom(ConfigModule.forRoot(environment, HEADER_DATA, errorsList, WebsiteThemes.lpe, QueriesId_VALUES)),
      DatePipe,
      provideEffyMaterial,
      {
        provide: ThemeHelperService,
        useClass: AcquisitionThemeHelperService,
      },
    ],
  ],
};
